import React, { Component } from "react";
import cons from "../../cons.js";

const BigNumber = require('bignumber.js');

export default class CrowdFunding extends Component {
  constructor(props) {
    super(props);

    this.state = {

      min: 100,
      deposito: "Loading...",
      balance: "Loading...",
      accountAddress: "0x0000000000000000000000000000000000000000",
      currentAccount: "0x0000000000000000000000000000000000000000",
      porcentaje: "Loading...",
      dias: "Loading...",
      partner: "Loading...",
      balanceTRX: "Loading...",
      balanceUSDT: "Loading...",
      precioSITE: 1,
      valueUSDT: 1,
      valueUSDTResult: 25,
      hand: 0

    };

    this.deposit = this.deposit.bind(this);
    this.estado = this.estado.bind(this);

    this.handleChangeUSDT = this.handleChangeUSDT.bind(this);
    this.handleChangeUSDTResult = this.handleChangeUSDTResult.bind(this);

  }

  async handleChangeUSDT(event) {

    let value = event.target.value

    if (value < 1) value = 1

    this.setState({
      valueUSDT: value,
      valueUSDTResult: parseInt(value * 25)
    });
  }

  async handleChangeUSDTResult(event) {

    let value = event.target.value

    this.setState({
      valueUSDTResult: value,
      valueUSDT: parseInt(value / 25)
    });
  }

  async componentDidMount() {

    setTimeout(() => {
      this.estado();
    }, 3 * 1000);

    setInterval(() => {
      this.estado();
    }, 5 * 1000);

  };

  async estado() {

    var accountAddress = this.props.currentAccount;
    var inversors = await this.props.wallet.contractBinary.methods.investors(this.props.currentAccount).call({ from: this.props.currentAccount });

    //console.log("estadooooo: ",await this.props.wallet.contractBinary.methods.setstate().call({from:this.props.currentAccount}))

    var inicio = accountAddress.substr(0, 4);
    var fin = accountAddress.substr(-4);

    var texto = inicio + "..." + fin;

    document.getElementById("login").href = `https://bscscan.com/address/${accountAddress}`;
    document.getElementById("login-my-wallet").innerHTML = texto;

    var nameToken1 = await this.props.wallet.contractToken.methods.symbol().call({ from: this.props.currentAccount });

    var aprovado = await this.props.wallet.contractToken.methods.allowance(accountAddress, this.props.wallet.contractBinary._address).call({ from: this.props.currentAccount });

    if (aprovado > 0) {
      if (!inversors.registered) {
        aprovado = "Register";
      } else {
        aprovado = "Buy Plan";
      }

    } else {
      aprovado = "Allow wallet";
    }

    inversors.inicio = 1000;

    var tiempo = await this.props.wallet.contractBinary.methods.tiempo().call({ from: this.props.currentAccount });

    tiempo = tiempo * 1000;

    var porcentiempo = ((Date.now() - inversors.inicio) * 100) / tiempo;

    var decimales = await this.props.wallet.contractToken.methods.decimals().call({ from: this.props.currentAccount });

    var balance = await this.props.wallet.contractToken.methods.balanceOf(this.props.currentAccount).call({ from: this.props.currentAccount });

    balance = new BigNumber(balance).shiftedBy(-decimales).toString(10);

    var valorPlan = 0;

    if (porcentiempo < 100) {
      aprovado = "Update Plan";

      valorPlan = inversors.plan / 10 ** 8;

    }

    var partner = cons.WS;

    var hand = "Left ";

    if (inversors.registered) {
      partner = await this.props.wallet.contractBinary.methods.padre(this.props.currentAccount).call({ from: this.props.currentAccount });

      let Left = await this.props.wallet.contractBinary.methods.misDirectos(partner, 0).call({ from: this.props.currentAccount })

      let Rigth = await this.props.wallet.contractBinary.methods.misDirectos(partner, 1).call({ from: this.props.currentAccount })


      //console.log(Left)

      //console.log(Rigth)

      let lado = ""

      for (let index = 0; index < Left.length; index++) {

        if (Left[index] === this.props.currentAccount) {
          lado = 0;

        }

      }

      for (let index = 0; index < Rigth.length; index++) {

        if (Rigth[index] === this.props.currentAccount) {
          lado = 1;

        }

      }


      if (lado === 0) {
        partner = "Left of " + partner
      }

      if (lado === 1) {
        partner = "Right of " + partner

      }

    } else {

      var loc = document.location.href;
      if (loc.indexOf('?') > 0) {
        var getString = loc.split('?');
        //console.log(getString)
        getString = getString[getString.length - 1];
        //console.log(getString);
        var GET = getString.split('&');
        var get = {};
        for (var i = 0, l = GET.length; i < l; i++) {
          var tmp = GET[i].split('=');
          get[tmp[0]] = unescape(decodeURI(tmp[1]));
        }

        if (get['hand']) {
          tmp = get['hand'].split('#');

          //console.log(tmp);

          if (tmp[0] === "right") {
            hand = "Rigth ";
          }
        }

        if (get['ref']) {
          tmp = get['ref'].split('#');

          //console.log(tmp[0]);

          var wallet = await this.props.wallet.contractBinary.methods.idToAddress(tmp[0]).call({ from: this.props.currentAccount });

          inversors = await this.props.wallet.contractBinary.methods.investors(wallet).call({ from: this.props.currentAccount });
          //console.log(wallet);
          if (inversors.registered) {
            partner = hand + " of " + wallet;
          }
        }


      }

    }

    if (partner === "0x0000000000000000000000000000000000000000") {
      partner = "---------------------------------";
    }

    var dias = await this.props.wallet.contractBinary.methods.tiempo().call({ from: this.props.currentAccount });

    //dias = (parseInt(dias)/86400);

    var porcentaje = await this.props.wallet.contractBinary.methods.porcent().call({ from: this.props.currentAccount });

    porcentaje = parseInt(porcentaje);

    var decimals = await this.props.wallet.contractToken.methods.decimals().call({ from: this.props.currentAccount });

    var balanceUSDT = await this.props.wallet.contractToken.methods.balanceOf(this.props.currentAccount).call({ from: this.props.currentAccount });

    balanceUSDT = parseInt(balanceUSDT) / 10 ** decimals;

    var id = await this.props.wallet.contractBinary.methods.addressToId(this.props.currentAccount).call({ from: this.props.currentAccount });


    this.setState({
      deposito: aprovado,
      balance: valorPlan,
      decimales: decimales,
      accountAddress: accountAddress,
      porcentaje: porcentaje,
      dias: dias,
      partner: partner,
      balanceSite: balance,
      balanceUSDT: balanceUSDT,
      nameToken1: nameToken1,
      id: id
    });
  }


  async deposit() {

    if (true) {
      this.setState({
        ModalTitulo: "Migration in progress",
        ModalBody: "At this moment we are in the migration process"
      })
      window.$("#alert").modal("show");

      return;
    }

    if (this.props.view) { window.alert("Is only view mode"); return; };

    var { balanceSite, valueUSDT, balance } = this.state;

    var aprovado = await this.props.wallet.contractToken.methods.allowance(this.props.currentAccount, this.props.wallet.contractBinary._address).call({ from: this.props.currentAccount });

    if (aprovado * 1 <= 0) {
      await this.props.wallet.contractToken.methods.approve(this.props.wallet.contractBinary._address, "115792089237316195423570985008687907853269984665640564039457584007913129639935").send({ from: this.props.currentAccount });
      window.alert("Balance approval for exchange: successful");
      aprovado = await this.props.wallet.contractToken.methods.allowance(this.props.currentAccount, this.props.wallet.contractBinary._address).call({ from: this.props.currentAccount });


    }

    var amount = await this.props.wallet.contractBinary.methods.plan().call({ from: this.props.currentAccount });
    amount = new BigNumber(amount).shiftedBy(-18).toNumber();
    amount = amount * valueUSDT;
    amount = amount - balance;

    if (aprovado > 0 && balanceSite >= amount) {

      var loc = document.location.href;
      var sponsor = cons.WS;
      var hand = 0;
      var investors = await this.props.wallet.contractBinary.methods.investors(this.props.currentAccount).call({ from: this.props.currentAccount });

      if (investors.registered) {

        sponsor = await this.props.wallet.contractBinary.methods.padre(this.props.currentAccount).call({ from: this.props.currentAccount });

      } else {

        if (loc.indexOf('?') > 0) {
          var getString = loc.split('?');
          getString = getString[getString.length - 1];
          //console.log(getString);
          var GET = getString.split('&');
          var get = {};
          for (var i = 0, l = GET.length; i < l; i++) {
            var tmp = GET[i].split('=');
            get[tmp[0]] = unescape(decodeURI(tmp[1]));
          }

          if (get['hand']) {

            tmp = get['hand'].split('#');

            if (tmp[0] === "right") {
              hand = 1;
            }
          }

          if (get['ref']) {
            tmp = get['ref'].split('#');

            var wallet = await this.props.wallet.contractBinary.methods.idToAddress(tmp[0]).call({ from: this.props.currentAccount });

            var padre = await this.props.wallet.contractBinary.methods.investors(wallet).call({ from: this.props.currentAccount });

            if (padre.registered) {
              sponsor = wallet;
            }
          }

        }

      }

      if (!investors.registered && sponsor !== "0x0000000000000000000000000000000000000000") {
        await this.props.wallet.contractBinary.methods.registro(sponsor, hand).send({ from: this.props.currentAccount });
        alert("congratulation registration: successful");
        sponsor = await this.props.wallet.contractBinary.methods.padre(this.props.currentAccount).call({ from: this.props.currentAccount });


      } else {
        if (!investors.registered) {
          alert("you need a referral link to register");
          return;
        }

      }


      if (((await this.props.wallet.contractBinary.methods.owner().call({ from: this.props.currentAccount })).toLowerCase() === (this.props.currentAccount).toLowerCase() || await this.props.wallet.contractBinary.methods.admin(this.props.currentAccount).call({ from: this.props.currentAccount }) || await this.props.wallet.contractBinary.methods.leader(this.props.currentAccount).call({ from: this.props.currentAccount }) || sponsor !== "0x0000000000000000000000000000000000000000") && investors.registered && parseInt(valueUSDT) > 0) {

        var userWithdrable = await this.props.wallet.contractBinary.methods.withdrawable(this.props.currentAccount).call({ from: this.props.currentAccount });
        var MIN_RETIRO = await this.props.wallet.contractBinary.methods.MIN_RETIRO().call({ from: this.props.currentAccount });

        var despositos = await this.props.wallet.contractBinary.methods.depositos(this.props.currentAccount).call({ from: this.props.currentAccount });


        if (userWithdrable / 10 ** 18 >= MIN_RETIRO / 10 ** 18 && despositos[0].length !== 0) {
          if (window.confirm(" desea Realizar el retiro de su disponible, para continuar")) {
            await this.props.wallet.contractBinary.methods.withdraw().send({ from: this.props.currentAccount })

            await this.props.wallet.contractBinary.methods.buyPlan(valueUSDT).send({ from: this.props.currentAccount })

            window.alert("Felicidades inversión exitosa");
            document.getElementById("services").scrollIntoView({ block: "start", behavior: "smooth" });


          } else {
            window.alert("al continuar sin retirar su progreso de binarios se perderá");
            await this.props.wallet.contractBinary.methods.buyPlan(valueUSDT).send({ from: this.props.currentAccount })

            window.alert("Felicidades inversión exitosa");
            document.getElementById("services").scrollIntoView({ block: "start", behavior: "smooth" });
          }

        } else {
          await this.props.wallet.contractBinary.methods.buyPlan(valueUSDT).send({ from: this.props.currentAccount })

          window.alert("Felicidades inversión exitosa");
          document.getElementById("services").scrollIntoView({ block: "start", behavior: "smooth" })

        }

      } else {

        if (valueUSDT <= 0) {
          window.alert("Invalid imput to buy a plan");
        } else {
          window.alert("Please use referral link to buy a plan");
        }

      }

    } else {


      if (balanceSite < amount) {

        window.alert("You do not have enough balance, you need: " + amount + " USDT and in your wallet you have: " + balanceSite);
      }


    }


  };


  render() {

    return (
      <>
        <div className="container">

          <div className="row">

            <div className="col-lg-6 col-md-6">
              <div className="icon-box" data-aos="zoom-in-left">
                <div className="icon"><i className="bi bi-person" style={{ "color": "rgb(7 89 232)" }}></i></div>
                <h4 className="title"><a href="#User">User </a></h4>
                <p className="description">
                  <strong >Wallet:</strong> <span style={{ "wordWrap": "break-word" }}>{this.state.accountAddress} ({this.state.id})</span><br />
                  <strong>USDT:</strong> {this.state.balanceSite}<br />
                  <strong>Partner: </strong> <span style={{ "wordWrap": "break-word" }}>{this.state.partner}</span>
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="icon-box" data-aos="zoom-in-left">
                <div className="icon"><i className="bi bi-currency-dollar" style={{ "color": "rgb(7 89 232)" }}></i></div>
                <h4 className="title text-center"><a href="#Invest">Invest </a></h4>
                <p className="description text-center">
                  <strong>{"Contract  /  USDT"}</strong>
                  <br />
                  <b className="text-center">
                    <input type={"number"} min="1" value={this.state.valueUSDT} step="1" onChange={this.handleChangeUSDT} />
                    {" = "}
                    <input type={"number"} value={this.state.valueUSDTResult} step="25" onChange={this.handleChangeUSDTResult} />
                  </b>
                  <br /><br />
                  <button className="btn btn-success" onClick={() => this.deposit()}>{this.state.deposito}</button>

                </p>
              </div>
            </div>

          </div>
        </div>













      </>


    );
  }
}